<!--直接加收款 directAddProceeds-->
<template>
  <div>
    <el-row style="margin-left: 10%;margin-top: 1%;size: B4">
      <el-col :span="11">
        <el-form label-width="100px" style="margin-top: 10px;margin-left: 10px"
                 :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm">

          <el-row>
            <el-col :span="22">
              <el-form-item label="收款项目" prop="proceedsProjectId" label-width="110px">
                <el-select v-model="ruleForm.proceedsProjectId" placeholder="请选择收款项目" clearable filterable>
                  <el-option
                      v-for="item in proceedsProjectArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      @click.native="proceedsProjectOnConfirm(item)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="到账金额" prop="receivedAmount" label-width="110px">
                <el-input v-model="ruleForm.receivedAmount"
                          placeholder="请填写到账金额"
                          type="number"
                          :rules="[{ required: true, message: '请填写到账金额' }]"
                          clearable
                          style="width: 217px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="到账账户" prop="receivedAccountId" label-width="110px">
                <el-select v-model="ruleForm.receivedAccountId" placeholder="请选择到账账户" clearable filterable>
                  <el-option
                      v-for="item in accountArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      @click.native="receivedAccountOnConfirm(item)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="备注" prop="remark" label-width="110px">
                <el-input
                    type="textarea"
                    style="width: 217px;"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    placeholder="请输入备注"
                    v-model="ruleForm.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="到账时间" prop="receivedDate" label-width="110px">
                <el-date-picker
                    v-model="ruleForm.receivedDate"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="选择到账时间"
                    align="right"
                    :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item style="margin-left: 10px;">
                <el-button type="primary" @click="directAddProceeds('ruleForm')">添加</el-button>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </el-col>
    </el-row>


  </div>
</template>

<script>


export default {
  name: "direct-add-proceeds",
  created() {
    this.pageInit()
  },
  data() {
    return {
      ruleForm:{
        proceedsProjectId: "",
        proceedsProjectName: "",
        receivedAmount: "",
        receivedAccountId: "",
        receivedAccountName: "",
        remark: "",
        receivedDate: this.$dateUtils.getTimeStr('s'), //要置空吗？
      },

      rules: {
        proceedsProjectId: [{required: true, message: '请选择收款项目', trigger: 'blur'}],
        receivedAmount: [{required: true, message: '请输入到账金额', trigger: 'blur'}],
        receivedAccountId: [{required: true, message: '请选择到账账户', trigger: 'blur'}],
        remark: [{required: true, message: '请输入备注', trigger: 'blur'}],
        transferTime: [{required: true, message: '请选择到账时间', trigger: 'blur'}],
      },

      proceedsProjectArray: [
        {value: 901, name: "利息"}, //先写死 后面改成查数据库的。
      ],

      accountArray: [],

      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  methods: {

    directAddProceeds(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (this.ruleForm.receivedAmount < 0) {
            this.$alert('到账金额不能为负数!', '', {
              type: "warning",
              confirmButtonText: '确定',
            });
            return false
          }

          this.$confirm("确定信息无误？",'直接加收款:', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(() =>
          {

            this.$axios(
                {
                  method: "POST",
                  url: "/proceeds/addProceeds",
                  data: {
                    'proceedsName': this.ruleForm.proceedsProjectId,
                    'receivedAmount': this.ruleForm.receivedAmount,
                    'receivedAccountId': this.ruleForm.receivedAccountId,
                    'remark': this.ruleForm.remark,
                    'receivedDate': this.ruleForm.receivedDate,
                    'createDate': this.ruleForm.receivedDate,
                    'empId': JSON.parse(localStorage.getItem('emp')).id,
                    'tenantCrop': localStorage.getItem("tenantCrop"),
                  },
                }).then(response => {

              if (response.data.code === 200)
              {
                this.$message.success("添加成功!")
                let self = this;
                setTimeout(function () {
                  // 刷新当前页面
                  location.reload();
                  // self.close()
                }, 1500)
              } else {
                this.$message.error(response.data.msg)
              }

            })


          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },


    pageInit() {
      /* 查到账账户参数 */
      this.$selectUtils.queryAccountIds().then(response => {
        this.accountArray = JSON.parse(response.data.data)
      })

      // this.queryProceedsList();
    },

    //下拉框项目选中触发的函数。
    receivedAccountOnConfirm: function (value) {
      this.ruleForm.receivedAccountId = value.value;
      this.ruleForm.receivedAccountName = value.name;
    },
    proceedsProjectOnConfirm: function (value) {
      this.ruleForm.proceedsProjectId = value.value;
      this.ruleForm.proceedsProjectName = value.name;
    },

    //级联选择关闭
    cascaderClsoe() {
      this.$refs.source.dropDownVisible = false;
    },

  },


}
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}

.avatar {
  width: 148px;
  height: 148px;
  display: block;
  border: #8c939d;
}
</style>